import React from 'react'
import { useParams } from "react-router-dom"

const ErrorPage = () => {

    const { statusCode } = useParams();

  return (
    <div className="text-3xl text-slate-300 font-medium px-12 py-8">

        {
            statusCode == 403
            ? <span>Oops! Looks like you don't have access to this page</span>
            : statusCode == 401
            ? <span>Oops! Looks like you're unauthorized to access this page</span>
            : statusCode == 404
            ? <span>Page not found</span>
            : <span>Oops! Something went wrong.</span>
        }
    </div>
  )
}

export default ErrorPage