import axios from "axios";
import React, { useEffect, useRef, useState } from 'react'
import { Outlet, useSearchParams, Link, useParams } from "react-router-dom"

const NavbarLayout = () => {

  const { orgId, suiteId } = useParams();

  const [ searchItem, setSearchItem ] = useSearchParams({ item: "" });
  const [ navbarHeight, setNavbarHeight ] = useState();
  const navbarRef = useRef();
  const [ searchData, setSearchData ] = useState();
  const [ searchResults, setSearchResults ] = useState([]);
  const [ showSearchResults, setShowSearchResults ] = useState(false);
  const containerRef = useRef();

  function throttle(func, limit) {
    let inThrottle;
    return function (...args) {
        const context = this;
        if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(() => inThrottle = false, limit);
        }
    };
  }

  function searchFunction(orgId, searchString, obj) {

    if (searchString.length < 3) return [];

    const results = [];

    for (const module of obj.modules) {

      console.log("lul ", module.name, searchString, module.name.includes(searchString))
        if (module.name.toLowerCase().includes(searchString.toLowerCase())) {
            results.push({
              tag:"Module",
              name:module.name,
              url:`${orgId}/${obj.suite_id}/${obj.suite_name}/modules/${module.id}`}
            );
        }

        for (const flow of module.flows) {
            if (flow.name.toLowerCase().includes(searchString.toLowerCase())) {
                results.push({
                  tag:"Flow",
                  name:flow.name,
                  url:`${orgId}/${obj.suite_id}/${obj.suite_name}/modules/${module.id}?flowId=${flow.id}`}
                );
            }

            for (const step of flow.steps) {
                if (step.name.toLowerCase().includes(searchString.toLowerCase())) {
                    results.push({
                      tag:"Step",
                      name:step.name,
                      url:`${orgId}/${obj.suite_id}/${obj.suite_name}/modules/${module.id}?flowId=${flow.id}&stepId=${step.id}`}
                    );
                }
            }
        }
    }
    console.log("search results are ", results);
    setSearchResults([...results]);
  }

  const throttledSearch = throttle(searchFunction, 0);

  const handleSearchInput = (e) =>{
    if(e.target.value?.length > 2){
      throttledSearch(63, e.target.value, searchData)
      setShowSearchResults(true);
    }
    else {
      setShowSearchResults(false);
    }
  }

  useEffect(()=>{
    setNavbarHeight(navbarRef.current?.clientHeight);
  }, [ navbarRef.current ])

  useEffect(()=>{
    const func = async () =>{
      const searchData = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/st/organisation/${orgId}/suite/${suiteId}`);
      setSearchData(searchData.data);
    }

    if( orgId, suiteId )
      func(); 
  }, [ orgId, suiteId ])

  useEffect(()=>{
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowSearchResults(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  },[])

  return (
    <div className="h-screen flex flex-col">
      <div id="navbarLayout" className="w-full py-3 px-4 3xl:py-4 flex items-center bg-[#1b1d1b]/50" ref={navbarRef}>
        <Link to={`/${ orgId }`}>        
          <img className="w-40 3xl:w-56" alt="Autoflow Logo" src="/logo.webp" />        
        </Link>
        {
          suiteId &&
          <div className="grow flex justify-center">
            <div ref={containerRef} className="relative py-2 3xl:py-4 px-4 md:px-6 3xl:px-8 lg:min-w-72 w-full md:w-2/3 lg:w-1/4 mx-auto flex items-center justify-between focus:bg-slate-300/40 focus:text-primary-color-dark border border-slate-300 bg-transparent rounded-xl group">
              {
                showSearchResults &&
                <div className="absolute w-full h-fit max-h-70 overflow-y-auto p-2 bg-[#1b1d1b] shadow-xl rounded-lg overflow-hidden top-12 3xl:top-16 left-0 z-20">
                  <ul>
                    {
                      searchResults.length > 0 ?
                      searchResults.map((item, index)=>(
                        <li className="px-3 py-2 rounded-lg hover:bg-slate-300/50 text-white hover:text-black group">
                          <Link className="flex items-center gap-2 justify-between" to={item.url} onClick={()=>setShowSearchResults(false)} >
                            <span>
                              { item.name }
                            </span>
                            <span className="py-1 px-2 rounded-lg group-hover:text-white bg-slate-300/50 text-[10px] font-light">
                              { item.tag }
                            </span>
                          </Link>
                        </li>
                      ))
                      :
                      <li className="px-3 py-2 rounded-lg hover:bg-slate-300/50 text-white hover:text-black">
                        No match found.
                      </li>
                    }
                  </ul>
                </div>
              }
              <input onChange={(e)=>handleSearchInput(e)} className="w-[calc(100%-1rem)] md:w-auto text-xs 3xl:text-base md:text-sm text-slate-300 font-medium bg-transparent focus:bg-transparent focus:outline-none focus:border-none" placeholder="Search" value={searchItem.item} />{/*onChange={(e)=>setSearchItem({ item: e.target.value })} />*/}
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-4 md:w-6 stroke-slate-300">
                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
              </svg>
            </div>
          </div>
        }
      </div>
      <div className={`grow h-[calc(100vh-76px)] bg-[#1b1d1b]/50`}>
        <Outlet />
      </div>
    </div>
  )
}

export default NavbarLayout