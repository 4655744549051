import React from 'react'
import TextSkeleton from "./TextSkeleton"

const ModuleCardSkeleton = (props) => {
  return (
    <div className="text-transparent border border-slate-300/50 rounded-lg p-2 3xl:p-4 flex flex-col gap-4 justify-between max-h-56 3xl:max-h-64 animate-pulse" >
        <div className="flex flex-col gap-2 3xl:gap-4 grow">
            <div className="flex justify-between items-start h-1/2 gap-2">
                <p className="text-lg md:text-xl lg:text-2xl 3xl:text-3xl font-medium w-full line-clamp-2 h-fit bg-slate-300/50 animate-pulse rounded-full">
                    temp
                </p>                    
                <div xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" className="stroke-slate-300 aspect-square w-5 3xl:w-7 mt-1 3xl:mt-2 bg-slate-300/50 animate-pulse rounded-lg">
                </div>
                
            </div>
            <div className="h-1/2">
                <p className="text-xs font-medium lg:text-sm bg-slate-300/50 animate-pulse rounded-full px-2 w-fit">
                    <span className="">
                        0
                    </span>
                    flows
                </p>
                <p className="text-xs md:text-sm lg:text-base 3xl:text-lg bg-slate-300/50 animate-pulse rounded-full mt-1 3xl:mt-2">
                    {"Last updated "}
                    <span className="font-medium">
                        temp
                    </span>
                </p>
            </div>
        </div>
        <div className="flex justify-center items-center gap-2 text-xs md:text-sm lg:text-base 3xl:text-lg hover:font-medium transition-colors duration-300 ease-out text-center py-2 md:py-4 w-full rounded-lg group bg-slate-300/50 animate-pulse">
            <span>
                Explore
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-0 group-hover:w-4 transition-all duration-300 ease-out stroke-primary-color-dark stroke-[1.5] group-hover:stroke-[2]">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
            </svg>
        </div>
    </div>
  )
}

export default ModuleCardSkeleton