import React, { useEffect, useState } from 'react'
import { Route, Routes, useParams } from "react-router-dom"
import Suite from "../components/Suite";
import Module from "./Module";
import axios from "axios";

const SuiteRoute = () => {

  const { orgId, suiteId } = useParams();
  const [ modules, setModules ] = useState([]);
  useEffect(()=>{

    const func = async () =>{
      const modules = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/st/organisation/${orgId}/suite/${suiteId}/modules`);
      setModules(modules?.data);
    }
    func();
  }, [])

  return (
    <>
      <Routes>
        <Route index element={<Suite modules={modules} />} />
        <Route path="/modules/:moduleId" element={<Module />} />
      </Routes>
    </>
  )
}

export default SuiteRoute