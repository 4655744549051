import React, { useState } from 'react'
import TextSkeleton from "./TextSkeleton";

const VerticalAccordian = (props) => {

    const [ isOpen, setIsOpen ] = useState(props.defaultOpen);

  return (
    <div className="">
        <div className="w-full cursor-pointer flex justify-between items-center" onClick={()=>{
            setIsOpen( prevState =>!prevState);
            props.onClick();
          }}>
            <div className="flex gap-2 grow">
              {
                props.indexing && 
                <div className="flex items-center justify-center w-6 h-6 p-1 text-xs lg:text-sm font-medium text-primary-color-dark bg-slate-300 rounded-full">
                  { props.index + 1 }
                </div>
              }
              
              { props.headingElement }
            </div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`${ isOpen ? "rotate-45" : "" } transition-all duration-300 ease-out origin-center w-4`}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
        </div>
        <div className={`ml-6 grid ${ isOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]" } transition-all ease-out duration-300 text-sm pl-3 3xl:pl-5 pt-2 3xl:pt-4`} >
          <div className={`${isOpen ? "" : "overflow-hidden"} flex flex-col font-light`}>
            { props.children }
          </div>
        </div>
    </div>
  )
}

export default VerticalAccordian