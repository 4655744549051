import React, { useState } from 'react'
import { Outlet, Link, useParams } from "react-router-dom"

const SidebarLayout = () => {

  const [ isOpen, setIsOpen ] = useState(false);
  const { suiteName, suiteId, orgId } = useParams();

  return (
    <div className="flex h-full">
        <div className={`${ isOpen ? "w-64 3xl:w-96" : "w-16 3xl:w-24" } h-full p-4 flex flex-col gap-8 3xl:gap-12 transition-all duration-300 ease-out text-slate-300`}>
          <svg onClick={()=>setIsOpen((prevState)=>!prevState)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className={`w-6 3xl:w-9 stroke-slate-300 transition-all duration-300 ease-out ${ isOpen ? " 3xl:ml-8" : "mx-auto" }`} style={{ transform: `${ isOpen ? "rotateY(180deg)" : "rotateY(0deg)" }` }}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          {orgId && <Link title="Suites" to={`/${orgId}`} onClick={()=>setIsOpen(false)} className={`flex items-center ${ isOpen ? "gap-4 3xl:ml-8" : "mx-auto" }`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" className="w-6 3xl:w-9 stroke-slate-300">
              <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
            </svg>
            {
              isOpen &&
              <span className="3xl:text-lg">
                Suites
              </span>
            }
            </Link>
          }
          { suiteName && suiteId && <Link title="Modules" to={`${orgId}/${suiteId}/${suiteName}`} onClick={()=>setIsOpen(false)} className={`flex items-center ${ isOpen ? "gap-4 3xl:ml-8" : "mx-auto" }`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" className="w-6 3xl:w-9 stroke-slate-300">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
            </svg>
            {
              isOpen &&
              <span className="3xl:text-lg">
                Modules
              </span>
            }
          </Link>}
        </div>
        <div className="grow h-full relative">
          {isOpen &&
            <div className="absolute w-full h-full backdrop-blur-md bg-black/75 z-20 rounded-lg"></div>
          }
          <Outlet />
        </div>
    </div>
  )
}

export default SidebarLayout