import React, { forwardRef, useEffect, useRef } from 'react'

const VideoWithSeek = forwardRef((props, ref) => {

    const myInterval = useRef();

    const handleVideoPlay = () =>{
        myInterval.current = setInterval(()=>{
            props.videoIntervalFunc && props.videoIntervalFunc(ref.current.currentTime)
        }, 250);
    }

    const handleVideoPause = () =>{
        console.log("video paused");
        clearInterval(myInterval?.current);
    }

    useEffect(()=>{

        if( ref.current )
            ref.current.currentTime = props.startTime || 0;

        return ()=> clearInterval(myInterval?.current);
    }, [])

  return (
    <div className="overflow-hidden lg:overflow-auto rounded-lg lg:rounded-none w-full h-fit lg:h-full bg-black flex justify-center items-center">
        <video ref={ref} controls src={props.src} onPlay={handleVideoPlay} onPause={handleVideoPause} className="w-full aspect-video lg:h-full max-w-full" />
    </div>
  )
})

export default VideoWithSeek