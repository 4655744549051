import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import axios from "axios";
import TextSkeleton from "../components/TextSkeleton";

const Home = () => {

  const [ suites, setSuites ] = useState([]);
  const { orgId } = useParams();

  const getSuiteRow = (suite) =>{
    return (
      <div>
        <Link className="hover:text-white" to={`${suite.id}/${suite.name}`}>
          { suite.name }
        </Link>
      </div>
    )
  }

  useEffect(()=>{

    const func = async () =>{
      const suites = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/st/organisation/${orgId}/suites`);
      // const publilshedSuites = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/st/organisation/${orgId}/published-buckets`)
      // const publihsedSuites = suites.data.filter((suite)=> publihsedSuites.some((publishedSuite)=> suite.id === publishedSuite.bucketId))
      setSuites(suites?.data);
    }

    func();

  },[])
  
  return (
    <div className="text-slate-300 rounded-lg bg-primary-color-dark h-full py-6 px-8 md:px-12 md:py-8 3xl:px-24 3xl:py-20 flex flex-col">
      <h1 className="text-slate-300 text-4xl 3xl:text-6xl font-medium h-fit">
        Suites
      </h1>
      <div className="mt-6 md:mt-8 flex flex-col gap-3 3xl:text-xl grow overflow-y-auto">
        {
          suites?.length > 0 ? suites?.map((suite)=>(
            getSuiteRow(suite)
          ))
          :
          <div className="flex flex-col gap-2">
            <TextSkeleton />
            <TextSkeleton />
            <TextSkeleton />
            <TextSkeleton />
            <TextSkeleton />
          </div>
        }
      </div>
    </div>
  )
}

export default Home