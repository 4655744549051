import React, { useEffect, useRef, useState } from 'react'
import VideoWithSeek from "../components/VideoWithSeek"
import { useParams, useSearchParams } from "react-router-dom";
import VerticalAccordian from "../components/VerticalAccordian";
import TextSkeleton from "../components/TextSkeleton";
import axios from "axios";
import moment from "moment";

const Module = () => {

  const { orgId, moduleId } = useParams();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ videoStartTime, setVideoStartTime ] = useState(0);
  const [ moduleData, setModuleData ] = useState();
  const [ currentVideoUrl, setCurrentVideoUrl ] = useState();
  const [ currentStepSSUrl, setCurrentStepSSUrl ] = useState();
  const [ stepMediaType, setStepMediaType ] = useState("VID");
  const videoRef = useRef();

  useEffect(()=>{
    const func = async () =>{
      const module = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/st/organisation/${orgId}/suite/module/${moduleId}/flows`);
      setModuleData(module.data);
      
      if( searchParams.get("flowId") ){
        getAndSetStepsForFlow(searchParams.get("flowId"));
        setCurrentVideoUrl(module?.data?.find((flow)=>flow.id === searchParams.get("flowId"))?.recordingUrl);

        if( searchParams.get("stepId") ){
          setCurrentStepSSUrl(module?.data?.find((flow)=>flow.id === searchParams.get("flowId"))?.steps?.find((step)=> step.id === searchParams.get("stepId")).screenShotUrl);
        }
        else{
          setCurrentStepSSUrl(module?.data?.find((flow)=>flow.id === searchParams.get("flowId"))?.steps[0]?.screenShotUrl);
        }
      }

      else{
        setCurrentVideoUrl(module?.data[0]?.recordingUrl);
        setCurrentStepSSUrl(module?.data[0]?.steps[0]?.screenShotUrl)
      }
    }
    
    func();
  }, [])

  const getAndSetStepsForFlow = async (flowId) =>{
    const steps = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/st/organisation/${orgId}/suite/module/flow/${flowId}`);

    setModuleData((prevState)=>{
      let updatedModuleData = [...prevState].map((flow)=>{
        if( flow.id === flowId ){
          return { ...flow, steps: steps.data.steps }
        }
         return flow;
      });
      return updatedModuleData
    })

  }

  const isVideoPlaying = video => (video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 0);

  const handleStepClick = (clickedStep, flowId) =>{

    setSearchParams({ ...searchParams, flowId: flowId, stepId: clickedStep.id });

    if( stepMediaType === "VID" && clickedStep.timeStamp ){
      if(videoRef?.current){
        videoRef.current.currentTime = typeof clickedStep.timeStamp == "number" ? (clickedStep.timeStamp + 250) / 1000 : 0;
        if( !isVideoPlaying(videoRef.current) ){
          console.log("b4 pausing vid");
          videoRef.current.pause();
        }
      }
    }
    else{
      setStepMediaType("SS")
      setCurrentStepSSUrl(clickedStep.screenShotUrl)
    }

  }

  const handleFlowClick = (clickedFlow) =>{

    setCurrentStepSSUrl(clickedFlow.steps[0]?.screenShotUrl);

    setSearchParams({ flowId: clickedFlow.id, stepId: "" });
    getAndSetStepsForFlow(clickedFlow.id);
  }

  const getTime = ( timeStamp ) =>{

    if( !timeStamp )
        return ""
    let tempTime = moment.duration(timeStamp);
    let minutes = tempTime.minutes();
    let seconds = tempTime.seconds();

    return (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
  }

  const getRowForStep = (step, stepIndex, flowIndex, flowId, numberOfSteps) =>{
    return (
      <div key={step.id} id={step.id} onClick={()=>handleStepClick(step, flowId)} className={`relative flex gap-2 justify-between items-center ${ searchParams.get("stepId") === step.id ? "text-green-600" : "text-slate-300 hover:text-white" }`} >
        <div className="absolute w-5 border border-dashed border-slate-300/50 -left-6">

        </div>
        <div className="flex gap-2 relative py-2 3xl:py-4">
          <div className={`absolute ${ stepIndex === numberOfSteps - 1 ? "h-1/2" : "h-full"} border border-dashed border-slate-300/50 top-0 -left-6`}>
          </div>
          <span className="text-[12px] lg:text-sm 3xl:text-base">
            {(flowIndex+1).toString() + "." + stepIndex.toString()}
          </span>
          <span className="text-[12px] lg:text-sm 3xl:text-base">
            { step?.name }
          </span>
        </div>
        <span className="text-[10px] lg:text-xs 3xl:text-sm text-slate-300/20">
          { 
            getTime(step?.timeStamp)
          }
        </span>
      </div>
    )
  }
  

  const getRowForFlow = (flow, index) =>{

    return (
      <div key={flow.id} id={flow.id} className="w-full cursor-pointer rounded-lg" > {/*onClick={()=>setCurrentVideoUrl(flow.videoUrl)}*/}
        <VerticalAccordian index={index} indexing={true} onClick={()=>handleFlowClick(flow)} defaultOpen={ searchParams.get("flowId") ? searchParams.get("flowId") === flow.id : index === 0 } headingElement={<h2 className="text-sm lg:text-base 3xl:text-xl">{flow.name}</h2>}>
          {
            flow?.steps?.length > 0 ? flow?.steps?.map((step, idx)=>(
              getRowForStep(step, idx, index, flow.id, flow.steps.length)
            )) :
            <div className="flex flex-col gap-1">
              <TextSkeleton />
              <TextSkeleton />
              <TextSkeleton />
            </div>
          }
        </VerticalAccordian>
      </div>
    )
  }

  const updateActiveStepFromTimeStamp = (timeStamp) =>{

    let closestIndex = -1;
    let minDiff = Infinity;
    const activeFlow = moduleData.find((flow)=> flow.id === searchParams.get("flowId")) || moduleData[0];

    if( activeFlow ){
      activeFlow.steps?.forEach((step, index) => {
        const diff = Math.abs(step.timeStamp / 1000 - timeStamp);

        if ( diff < 0.25 && diff < minDiff) {
          minDiff = diff;
          closestIndex = index;
        }
      });

      if( closestIndex >= 0 ){
        const activeStepId = activeFlow.steps[closestIndex].id;
        setSearchParams({ ...searchParams, stepId: activeStepId })
      }

    }

  }

  return (
    <div className="w-full flex flex-col lg:flex-row transition-all duration-300 ease-out gap-4 xl:gap-8 rounded-lg bg-primary-color-dark p-6 h-full">
      <div className="w-full h-fit lg:h-full lg:w-[65%] flex flex-col gap-2">
        <div className="w-full xl:w-1/2 mx-auto p-1 flex border border-slate-300/30 rounded-lg text-sm xl:text-base">
          <div onClick={()=>setStepMediaType("VID")} className={`${stepMediaType === "VID" ? "bg-slate-300" : "text-slate-300" }  w-1/2 text-center py-1 cursor-pointer rounded-lg`}>
            Video
          </div>
          <div onClick={()=>setStepMediaType("SS")} className={`${stepMediaType === "SS" ? "bg-slate-300" : "text-slate-300" }  w-1/2 text-center py-1 cursor-pointer rounded-lg`}>
            Screenshot
          </div>
        </div>
        <div className="grow rounded-xl lg:overflow-hidden">
          { (stepMediaType === "VID" && currentVideoUrl) && <VideoWithSeek startTime={ videoStartTime } videoIntervalFunc={updateActiveStepFromTimeStamp} ref={videoRef} src={currentVideoUrl} />}
          { (stepMediaType === "SS" && currentStepSSUrl) && <img className="aspect-video w-full h-fit lg:h-full" src={currentStepSSUrl} />}
          <img className="aspect-video w-full h-fit lg:h-full" src={currentStepSSUrl} />
        </div>
      </div>
      <div className="w-full h-[calc(100vh-56.25vw-7rem)] lg:h-full lg:grow lg:w-[35%] px-2 flex flex-col rounded-lg bg-[#1b1d1b]/50 backdrop-blur-lg">
        <div className="p-3 lg:p-6 h-fit">
          <h1 className="text-2xl lg:text-3xl 3xl:text-5xl text-slate-300 font-medium">
            Flows
          </h1>
        </div>
        <div className="px-3 lg:px-6 text-slate-300 flex flex-col gap-3 lg:gap-5 overflow-y-auto">
          { moduleData?.map((flow, index)=>(
              getRowForFlow(flow, index)
            )
            )
            ||
            <div className="flex flex-col gap-4">
              <TextSkeleton className="w-36 xl:w-52 h-4" />
              <TextSkeleton className="w-36 xl:w-52 h-4" />
              <TextSkeleton className="w-36 xl:w-52 h-4" />
              <TextSkeleton className="w-36 xl:w-52 h-4" />
            </div>
            }
        </div>
      </div>
    </div>
  )
}

export default Module