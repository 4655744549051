import { useState, useEffect } from "react";
import './App.css';
import { Link, Route, Routes, useParams } from "react-router-dom";
import Home from "./pages/Home";
import SuiteRoute from "./pages/SuiteRoute";
import NavbarLayout from "./layouts/NavbarLayout";
import NotFound from "./pages/NotFound";
import SidebarLayout from "./layouts/SidebarLayout";
import ErrorPage from "./pages/ErrorPage";
import axios from "axios";

function App() {

  return (
    <>
      <Routes>
        <Route element={<NavbarLayout />} >
          <Route element={<SidebarLayout />}>
            <Route path="/:orgId" element={<Home />} />
            <Route path="/:orgId/:suiteId/:suiteName/*" element={<SuiteRoute />} ></Route>
          </Route>
        </Route>
        <Route path="/error/:statusCode" element={<ErrorPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
